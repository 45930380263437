import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Grid } from "@material-ui/core/"
import { makeStyles } from "@material-ui/core/styles/"
import { Paper } from "@material-ui/core/"
import { Typography } from "@material-ui/core/"
import { ContactModal } from "./contactModal"
import { Hidden } from "@material-ui/core/"
import { Divider } from "@material-ui/core/"
import { Link as RouterLink } from "gatsby"

const useStyles = makeStyles(theme => ({
  imageContainer: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
  cover: {
    position: "absolute",
    width: "100%",
    height: "45vw",
    [theme.breakpoints.down("sm")]: {
      height: "65vw",
    },
    [theme.breakpoints.down("md")]: {
      height: "75vw",
    },
    overflow: "hidden",
  },
  photoGrid: {
    position: "relative",
    width: "100%",
    height: "45vw",
    [theme.breakpoints.down("sm")]: {
      height: "65vw",
    },
    [theme.breakpoints.down("md")]: {
      height: "75vw",
    },
  },
  image: {
    flexShrink: 0,
    width: "100%",
    height: "100%",
  },
  highlightText: {
    textAlign: "left",
    fontSize: "6rem",
    [theme.breakpoints.down("xl")]: {
      fontSize: "5rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "4rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "3rem",
      textShadow: "0px 0px 10px #555",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.8rem",
    },
    color: "#fff",
    textShadow: "0px 0px 10px #555",
  },
  highlightSubText: {
    textAlign: "left",
    fontSize: "5rem",
    [theme.breakpoints.down("xl")]: {
      fontSize: "3rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
      textShadow: "0px 0px 10px #555",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
    color: "#fff",
    textShadow: "0px 0px 10px #555",
    marginBottom: "1rem",
  },
  highlightlist: {
    fontSize: "2rem",
    [theme.breakpoints.down("xl")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      textShadow: "0px 0px 2px #ddd",
      color: "#000",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
      color: "#000",
    },
    color: "#fff",
    textShadow: "0px 0px 10px #555",
    marginBottom: "1rem",
  },
  highlightContent: {
    textAlign: "left",
    backgroundColor: "transparent",
    border: "none",
  },
  logo: {
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "65%",
    },
    margin: "auto",
  },
  highlightGrid: {
    position: "absolute",
    width: "100%",
    height: "45vw",
    [theme.breakpoints.down("sm")]: {
      height: "65vw",
    },
    [theme.breakpoints.down("md")]: {
      height: "75vw",
    },
  },
  divider: {
    background: "#ddd",
    width: "35vw",
    margin: "1rem auto",
  },
  links: {
    textDecoration: "none",
    color: "inherit",
    cursor: "pointer"
  }
}))

export default function DisplayBanner() {
  const classes = useStyles()

  return (
    <React.Fragment>
      <Grid container item sm={12} className={classes.photoGrid}>
        <Grid item xs={12} className={classes.cover}>
          <div className="imageContainer">
            <StaticImage
              src="../images/happy-kid.jpg"
              formats={["auto", "webp", "avif"]}
              alt="Cover Image"
              className={classes.imageContainer}
            />
          </div>
        </Grid>
        <Grid
          container
          item
          sm={12}
          alignItems="center"
          justifyContent="flex-end"
          className={classes.highlightGrid}
        >
          <Grid item xs={7}>
            <Paper className={classes.highlightContent} elevation={0}>
              <Typography variant="h1" className={classes.highlightText}>
                Amrish Prasher
              </Typography>
              <Typography variant="h3" className={classes.highlightSubText}>
                Insurance Advisor
              </Typography>
              <Typography variant="h3" className={classes.highlightSubText}>
                <i> Protect your future ... </i>
              </Typography>
              <Hidden smDown>
                <Typography variant="h3" className={classes.highlightlist}>
                  <li> <RouterLink className={classes.links} to="#Super-Visa-Insurance">Super Visa Insurance </RouterLink></li>
                  <li> <RouterLink className={classes.links} to="#Visitor-Insurance">Visitor Visa Insurance </RouterLink></li>
                  <li> <RouterLink className={classes.links} to="#Travel-Insurance">Travel Insurance </RouterLink></li>
                  <li> <RouterLink className={classes.links} to="#Life-Insurance">Life Insurance </RouterLink></li>
                  <li> <RouterLink className={classes.links} to="#Critical-Illness-Insurance">Critical Illness Insurance </RouterLink></li>
                  <li> <RouterLink className={classes.links} to="#Disability-Insurance">Disability Insurance </RouterLink></li>
                  <li> <RouterLink className={classes.links} to="#Registered-Education-Savings-Plan">Registered Education Savings Plan (RESP) </RouterLink></li>
                  <li> <RouterLink className={classes.links} to="#Registered-Retirement-Savings-Plan">Registered Retirement Savings Plan (RRSP) </RouterLink></li>
                </Typography>
                <ContactModal />
              </Hidden>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Hidden mdUp>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography variant="h3" className={classes.highlightlist}>
          <RouterLink className={classes.links} to="#Super-Visa-Insurance">Super Visa Insurance </RouterLink><br/>
          <RouterLink className={classes.links}  to="#Visitor-Insurance">Visitor Visa Insurance  </RouterLink><br/>
          <RouterLink className={classes.links} to="#Travel-Insurance">Travel Insurance </RouterLink><br/>
          <RouterLink className={classes.links} to="#Life-Insurance">Life Insurance </RouterLink><br/>
          <RouterLink className={classes.links} to="#Critical-Illness-Insurance">Critical Illness Insurance </RouterLink><br/>
          <RouterLink className={classes.links} to="#Disability-Insurance">Disability Insurance </RouterLink><br/>
          <RouterLink className={classes.links} to="#Registered-Education-Savings-Plan">Registered Education Savings Plan (RESP) </RouterLink><br/>
          <RouterLink className={classes.links} to="#Registered-Retirement-Savings-Plan">Registered Retirement Savings Plan (RRSP) </RouterLink><br/>
            </Typography>
            <ContactModal variant="outlined" color="primary" />
          </Grid>
          <Grid item xs={12}>
            <Divider className={classes.divider}></Divider>
          </Grid>
        </Hidden>
      </Grid>
    </React.Fragment>
  )
}
