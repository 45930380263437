import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { Grid, Typography, Card, CardContent, Divider } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles/"

const useStyles = makeStyles(theme => ({
  thumbnail: {
    height: "30vh",
    width: "30vh",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    borderRadius: "50%",
  },
  imageContainer: {
    margin: "auto",
    textAlign: "center",
  },
  textContainer: {
    padding: "2rem",
  },
  textContainerRight: {
    padding: "2rem",
    textAlign: "right",
    [theme.breakpoints.down("md")]: {
      textAlign: "left",
    },
  },
  titleText: {
    marginBottom: "1rem",
  },
  offering: {
    margin: "1rem",
  },
  divider: {
    background: "#ddd",
    width: "35vw",
    margin: "1rem auto",
  },
  bodyText: {
    fontSize: "1rem",
  },
}))

export default function Offering(props) {
  const classes = useStyles()

  return (
    <Grid container>
      <Grid
        container
        item
        xs={12}
        className={classes.offering}
        id="Super-Visa-Insurance"
        alignItems="center"
        direction="row-reverse"
      >
        <Grid item className={classes.imageContainer} xs={12} md={6}>
          <StaticImage
            className={classes.thumbnail}
            alt="thumbnail"
            src="../images/supervisa.jpg"
          ></StaticImage>
        </Grid>
        <Grid item xs={12} md={6} className={classes.textContainerRight}>
          <Card elevation={0}>
            <CardContent>
              <Typography variant="h4" className={classes.titleText}>
                Super Visa Insurance
              </Typography>
              <Typography variant="body2" className={classes.bodyText}>
                As part of the Super Visa application process, applicants must
                confirm they have emergency medical travel insurance coverage in
                place for at least one year after arriving in Canada. Canadian
                residents may purchase medical insurance on behalf of their
                visiting family member(s) who are part of the Super Visa
                program, and should be aware that if they have sponsored their
                stay, are responsible for their medical bills in the event that
                they are unable to pay. This is one of the reasons why it is
                essential for a Super Visa applicant to be covered with the
                right medical insurance plan for the duration of their stay.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Divider className={classes.divider}></Divider>
      <Grid
        container
        item
        xs={12}
        className={classes.offering}
        id="Visitor-Insurance"
        alignItems="center"
      >
        <Grid item className={classes.imageContainer} xs={12} md={6}>
          <StaticImage
            className={classes.thumbnail}
            alt="thumbnail"
            src="../images/visitor.jpg"
          ></StaticImage>
        </Grid>
        <Grid item xs={12} md={6} className={classes.textContainer}>
          <Card elevation={0}>
            <CardContent>
              <Typography variant="h4" className={classes.titleText}>
                Visitor Visa Insurance
              </Typography>
              <Typography variant="body2" className={classes.bodyText}>
                If you’re planning on visiting Canada, or if you have friends
                and family visiting, you’ll want to enjoy the visit without
                disruptions. If health emergencies come up, you can get help
                covering the expenses with travel medical coverage that's
                simple, affordable and easy to use so you don’t end up paying
                out-of-pocket. Get emergency medical care and more with CoverMe
                travel insurance for visitors to Canada.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Divider className={classes.divider}></Divider>
      <Grid
        container
        item
        xs={12}
        className={classes.offering}
        id="Travel-Insurance"
        alignItems="center"
        direction="row-reverse"
      >
        <Grid item className={classes.imageContainer} xs={12} md={6}>
          <StaticImage
            className={classes.thumbnail}
            alt="thumbnail"
            src="../images/travel.jpg"
          ></StaticImage>
        </Grid>
        <Grid item xs={12} md={6} className={classes.textContainerRight}>
          <Card elevation={0}>
            <CardContent>
              <Typography variant="h4" className={classes.titleText}>
                Travel Insurance
              </Typography>
              <Typography variant="body2" className={classes.bodyText}>
                Thousands on Canadians make air travel every year, most of these
                travelers have one thing common among them, that is, they are
                haunted by the requirement of travel insurance in Canada. To
                begin with, there are many different types of insurance
                available in market today, and picking the right one can be
                tricky.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Divider className={classes.divider}></Divider>

      <Grid
        container
        item
        xs={12}
        className={classes.offering}
        id="Life-Insurance"
        alignItems="center"
      >
        <Grid item className={classes.imageContainer} xs={12} md={6}>
          <StaticImage
            className={classes.thumbnail}
            alt="thumbnail"
            src="../images/life.jpg"
          ></StaticImage>
        </Grid>
        <Grid item xs={12} md={6} className={classes.textContainer}>
          <Card elevation={0}>
            <CardContent>
              <Typography variant="h4" className={classes.titleText}>
                Life Insurance
              </Typography>
              <Typography variant="body2" className={classes.bodyText}>
                Thousands on Canadians make air travel every year, most of these
                travelers have one thing common among them, that is, they are
                haunted by the requirement of travel insurance in Canada. To
                begin with, there are many different types of insurance
                available in market today, and picking the right one can be
                tricky.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Divider className={classes.divider}></Divider>
      <Grid
        container
        item
        xs={12}
        className={classes.offering}
        id="Critical-Illness-Insurance"
        alignItems="center"
        direction="row-reverse"
      >
        <Grid item className={classes.imageContainer} xs={12} md={6}>
          <StaticImage
            className={classes.thumbnail}
            alt="thumbnail"
            src="../images/critical.jpg"
          ></StaticImage>
        </Grid>
        <Grid item xs={12} md={6} className={classes.textContainerRight}>
          <Card elevation={0}>
            <CardContent>
              <Typography variant="h4" className={classes.titleText}>
                Critical Illness Insurance
              </Typography>
              <Typography variant="body2" className={classes.bodyText}>
                Critical illness insurance assists you to pay for the expenses
                related with life-threatening illnesses. It provides you with a
                lump sum payment in case you suffer from an illness that is
                covered by the policy. Get to know more about critical illness
                insurance and availability of the numerous benefits associated
                with this policy.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Divider className={classes.divider}></Divider>
      <Grid
        container
        item
        xs={12}
        className={classes.offering}
        id="Disability-Insurance"
        alignItems="center"
      >
        <Grid item className={classes.imageContainer} xs={12} md={6}>
          <StaticImage
            className={classes.thumbnail}
            alt="thumbnail"
            src="../images/disability.jpg"
          ></StaticImage>
        </Grid>
        <Grid item xs={12} md={6} className={classes.textContainer}>
          <Card elevation={0}>
            <CardContent>
              <Typography variant="h4" className={classes.titleText}>
                Disability Insurance
              </Typography>
              <Typography variant="body2" className={classes.bodyText}>
                Disability insurance is a policy that supplements your income
                when you become incapable of working to the best of your
                potential because of a disability. In other words, it partially
                neutralizes the financial risk posed by a disability that
                hampers your income earning potential.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Divider className={classes.divider}></Divider>
      <Grid
        container
        item
        xs={12}
        className={classes.offering}
        id="Registered-Education-Savings-Plan"
        alignItems="center"
        direction="row-reverse"
      >
        <Grid item className={classes.imageContainer} xs={12} md={6}>
          <StaticImage
            className={classes.thumbnail}
            alt="thumbnail"
            src="../images/resp.jpg"
          ></StaticImage>
        </Grid>
        <Grid item xs={12} md={6} className={classes.textContainerRight}>
          <Card elevation={0}>
            <CardContent>
              <Typography variant="h4" className={classes.titleText}>
                Registered Education Savings Plan (RESP)
              </Typography>
              <Typography variant="body2" className={classes.bodyText}>
                An RESP is an investment account designed to help you save for a
                child's education. They’re regulated accounts to be used for
                saving money for a child’s post-secondary education. The main
                benefit of an RESP is its tax-advantaged nature.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Divider className={classes.divider}></Divider>
      <Grid
        container
        item
        xs={12}
        className={classes.offering}
        id="Registered-Retirement-Savings-Plan"
        alignItems="center"
      >
        <Grid item className={classes.imageContainer} xs={12} md={6}>
          <StaticImage
            className={classes.thumbnail}
            alt="thumbnail"
            src="../images/rrsp.jpg"
          ></StaticImage>
        </Grid>
        <Grid item xs={12} md={6} className={classes.textContainer}>
          <Card elevation={0}>
            <CardContent>
              <Typography variant="h4" className={classes.titleText}>
                Registered Retirement Savings Plan (RRSP)
              </Typography>
              <Typography variant="body2" className={classes.bodyText}>
                A Registered Retirement Savings Plan (RRSP) is a retirement
                savings and investing vehicle for employees and the
                self-employed in Canada. Pre-tax money is placed into an RRSP
                and grows tax-free until withdrawal, at which time it is taxed
                at the marginal rate.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  )
}
